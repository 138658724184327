import { gql } from '@apollo/client';
import {
  RatificationResultFragment,
  RatificationResultVariableContributionFragment,
} from './fragments';

export const RATIFICATION_RESULTS = gql`
  query ratificationResults($riskEvaluationId: Int!) {
    ratificationResults(riskEvaluationId: $riskEvaluationId) {
      ...RatificationResultFragment
      ratificationModel {
        id
        predictionType {
          id
          ratificationEnumIfHolds
        }
      }
      variablesContribution {
        ...RatificationResultVariableContributionFragment
      }
    }
  }
  ${RatificationResultVariableContributionFragment}
  ${RatificationResultFragment}
`;

export const RATIFICATION_VARIABLE_CONTRIBUTION = gql`
  query ratificationVariableContribution($riskEvaluationId: Int!) {
    ratificationVariableContribution(riskEvaluationId: $riskEvaluationId) {
      ...RatificationResultVariableContributionFragment
    }
  }
  ${RatificationResultVariableContributionFragment}
`;
