import { gql } from '@apollo/client';
import { BasicUserFields } from '../fragments';

export const RatificationResultFragment = gql`
  fragment RatificationResultFragment on RatificationResultType {
    id
    createdAt
    modelProbability
    thresholdUsed
    ratificationResult
    globalAppId
    minutesUntilAutomaticApproval
    rejectedBy {
      ... BasicUserFields
    }
    rejectedAt
  }
  ${BasicUserFields}
`;
export const RatificationResultVariableContributionFragment = gql`
  fragment RatificationResultVariableContributionFragment on RatificationVariableContributionType {
    id
    variableValue
    contribution
    variableDescription {
      id
      name
      labelName
      description
    }
  }
`;

export const RiskPredictionResultTypeFragment = gql`
  fragment RiskPredictionResultTypeFragment on RiskPredictionResultType {
    id
    prediction
    offeredValue
    rejectedValue
    thresholdUsed
  }
`;

export const RiskPredictionVariableContributionModelTypeFragment = gql`
  fragment RiskPredictionVariableContributionModelTypeFragment on RiskPredictionVariableContributionModelType {
    id
    variableName {
      id
      name
      labelName
    } 
    variableValue
    contribution
  }
`;
