/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import {
  CollectionActionsFields,
  CollectionPurchaseOrderFields,
} from './fragments';
import { ContactsField } from '../customers/fragment';
import { UserIdentification } from '../user/fragments';

export const GET_COLLECTION_ACTIONS = gql`
  query getCollectionActions($first: Int, $after: Int, $id_In: [String]) {
    collectionActions(first: $first, after: $after, id_In: $id_In) {
      ...CollectionActionsFields
      contacts {
        ...ContactsField
      }
      author {
        ...UserIdentification
      }
    }
  }
  ${CollectionActionsFields}
  ${ContactsField}
  ${UserIdentification}
`;

export const COLLECTION_PURCHASE_ORDERS = gql`
  query CollectionPurchaseOrders(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id_In: [String]
    $companyId: Int
    $orderBy: String
    $inSimulation: Boolean
    $inOffer: Boolean
    $inOperation: Boolean
    $inEvaluation: Boolean
    $inOperationActive: Boolean
    $finishedOperations: Boolean
    $inPendingInvoices: Boolean = false
    $availableForTransfer: Boolean = false
    $inCollection: Boolean = false
    $globalFilter: String
    $purchaseOrderSecretKey: String
    $status: [String]
  ) {
    purchaseOrders(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id_In: $id_In
      companyId: $companyId
      orderBy: $orderBy
      inSimulation: $inSimulation
      inOffer: $inOffer
      inOperation: $inOperation
      inEvaluation: $inEvaluation
      inOperationActive: $inOperationActive
      finishedOperations: $finishedOperations
      inPendingInvoices: $inPendingInvoices
      availableForTransfer: $availableForTransfer
      inCollection: $inCollection
      globalFilter: $globalFilter
      purchaseOrderSecretKey: $purchaseOrderSecretKey
      status: $status
    ) {
      totalCount
      totalPages
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...CollectionPurchaseOrderFields
        }
      }
    }
  }
  ${CollectionPurchaseOrderFields}
`;
