/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { ConfirmingPayrollFields, MasterEntityFields } from '../fragments';
import { MoneyFields } from '../moneyFields';

export const CONFIRMING_CREDIT_LINE = gql`
  {
    confirmingAnalytics {
      payrollId
      totalInterest
      totalAmortization
      operation_ExpirationDate
    }
  }
`;

export const CONFIRMING_CURRENT_AMOUNTS_ANALYTICS = gql`
  {
    confirmingCurrentAmountsAnalytics
  }
`;

export const CONFIRMING_PAYROLL_SUMMARY_ANALYTICS = gql`
  {
    confirmingPayrollSummaryAnalytics {
      pendingPayrolls
      pendingCessionOperations
      initiatedPayrolls
    }
  }
`;

export const CONFIRMING_CURRENT_SUPPLIER_ANALYTICS = gql`{
    confirmingCurrentSuppliersAnalytics
  }
`;

export const CONFIRMING_INVOICE_EXPIRAION_ANALYTICS = gql`
  query confirmingAnalytics($expirationDateLte: DateTime!, $expirationDateGte: DateTime!, $groupBy: String!) {
    confirmingInvoiceExpirationAnalytics(dateLte: $expirationDateLte, dateGte: $expirationDateGte, groupBy: $groupBy)
  }
`;

export const CONFIRMING_CONFIRMINGS = gql`
  query confirmings($invoiceMoneyTransfer_Status: String) {
    confirmings(invoiceMoneyTransfer_Status: $invoiceMoneyTransfer_Status) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
          dateToPay
          folio
          invoiceMoneyTransfer {
            id
            createdAt
          }
          amountWithIva {
            ...MoneyFields
          }
          company {
            id
            name
            rut
            masterEntity {
              ...MasterEntityFields
            }
            companyevaluationrestrictionsSet {
              id
              restriction
              description
              status
              resolutionExplanation
            }
            documents {
              documentType
              lastDate
              lastFile
            }
          }
          receiver {
            id
            masterEntity {
              ...MasterEntityFields
            }
          }
        }
      }
    }
  }
  ${MoneyFields}
  ${MasterEntityFields}
`;

export const CONFIRMING_PAYROLLS = gql`
  query confirmingPayrolls($first: Int, $offset: Int, $status_Status: String, $payrollStatus: String, $createdAt_Gte: DateTime, $createdAt_Lte: DateTime, $operations_ExpirationDate_Gte: Date, $operations_ExpirationDate_Lte: Date, $amountBetween: [String]) {
    confirmingPayrolls(first: $first, offset: $offset, status_Status: $status_Status, payrollStatus: $payrollStatus, createdAt_Gte: $createdAt_Gte, createdAt_Lte: $createdAt_Lte, operations_ExpirationDate_Gte: $operations_ExpirationDate_Gte, operations_ExpirationDate_Lte: $operations_ExpirationDate_Lte, amountBetween: $amountBetween) {
      totalCount
      totalPages
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...ConfirmingPayrollFields  
        }
      }
    }
  }
${ConfirmingPayrollFields}
`;

export const CONFIRMING_PAYROLL = gql`
  query confirmingPayroll($payrollId: ID!) {
    confirmingPayroll(payrollId: $payrollId) {
      ...ConfirmingPayrollFields
    }
  }
${ConfirmingPayrollFields}
`;

export const EXPORT_CONFIRMING_PAYROLL = gql`
  query exportConfirmingPayroll($payrollId: ID, $dateLte: DateTime, $dateGte: DateTime) {
    exportConfirmingPayroll(payrollId: $payrollId, dateLte: $dateLte, dateGte: $dateGte)
  }
`;

export const SEND_CESSION_REMINDER_MAIL = gql`
mutation sendCessionReminderMail($confirmingIds: [ID]!) {
  sendCessionReminderMail(confirmingIds: $confirmingIds) {
    success
  }
}`;
