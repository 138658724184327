import { gql } from '@apollo/client';
import { UserIdentification } from '../user/fragments';
import { MoneyFields } from '../moneyFields';
import { BaseContactsField } from '../customers/fragment';

export const CollectionActionsFields = gql`
  fragment CollectionActionsFields on CollectionActionType {
    id
    comment
    actionType
    createdAt
    contacts(limit:5) {
      ...BaseContactsField
    }
  }
  ${BaseContactsField}
`;

export const CollectionManagerBasicFields = gql`
  fragment CollectionManagerBasicFields on CollectionManagerType {
    id
    status
    forReintegration
  }
`;

export const CollectionPriorityFields = gql`
  fragment CollectionPriorityFields on CollectionPriorityTypeType {
    id
    value
  }
`;

export const DataForCollectionFields = gql`
  fragment DataForCollectionFields on DataForCollectionType {
    id
    dateToPay
    paymentMethod
  }
`;

export const CollectionPurchaseOrderFields = gql`
  fragment CollectionPurchaseOrderFields on OrderingPurchaseOrderType {
    id
    orderNumber
    publicationDate
    company {
      id
      masterEntity {
        id
        name
        displayNationalIdentifier
      }
      executiveAssigned {
        ...UserIdentification
      }
    }
    purchaser {
      id
      name
      displayNationalIdentifier
    }
    totalAmount {
      ...MoneyFields
    }
    orderingDebt {
      id
      debt {
        ...MoneyFields
      }
    }
    orderingoffer {
      id
      invoiceIssuedDate
      orderingFinancedAmount {
        ...MoneyFields
      }
    }
    collectionManager {
      id
      collector {
        ...UserIdentification
      }
      collectionPriority {
        id
        value
      }
      currentDataForCollection {
        id
        dateToPay
      }
      actions {
        id
        comment
        createdAt
        actionType
        contacts {
            ...BaseContactsField
        }
        author {
          ...UserIdentification
        }
      }
    }
  }
  ${MoneyFields}
  ${UserIdentification}
  ${BaseContactsField}
`;
