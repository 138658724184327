import { gql } from '@apollo/client';

export const ACTIVATE_COLLECTION_PLAN = gql`
  mutation ActivateCollectionPlan($planId: ID!) {
    activateCollectionPlan(planId: $planId) {
      user {
        id
        permissions
        userPlanSubscriptions(isActive: true) {
          edges {
            node {
              id
              isActive
              isAdmin
            }
          }
        }
        selectedCompany {
          id
          hasCollectionAcceptedTerms @client
          currentUserAcceptedTerms {
            id
            product
          }
          masterEntity {
            id
            subscriptions(isActive: true) {
              edges {
                node {
                  id
                  isActive
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const REQUEST_COLLECTION_PLAN_DEMO = gql`
  mutation RequestCollectionPlanDemo(
    $name: String!
    $phoneNumber: String!
    $email: String!
  ) {
    requestCollectionPlanDemo(
      name: $name
      phoneNumber: $phoneNumber
      email: $email
    ) {
      success
    }
  }
`;

export const SET_DEBTOR_PREFERENCES = gql`
  mutation setDebtorPreferences($companyId: ID!, $masterEntityId: ID, $expirationDays: Int!, $allDebtors: Boolean!) {
    setDebtorPreferences(companyId: $companyId, masterEntityId: $masterEntityId, expirationDays: $expirationDays, allDebtors: $allDebtors ) {
      preferences {
        id
        expirationDays
      }
    }
  }
`;
