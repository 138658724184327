import { gql } from '@apollo/client';
import { RiskBlacklistFields, MasterEntityBasicField } from '../customers/fragment';
import { RatificationResultFragment, RatificationResultVariableContributionFragment, RiskPredictionVariableContributionModelTypeFragment } from './fragments';

export const RUN_RATIFICATION_CONTRIBUTION = gql`
  mutation runRatificationContribution(
    $ratificationResultId: Int!
  ) {
    runRatificationContribution(
      ratificationResultId: $ratificationResultId
    ) {
      ratificationResult {
        ... RatificationResultFragment
        variablesContribution {
          ... RatificationResultVariableContributionFragment
        }
      }
    }
  }
  ${RatificationResultVariableContributionFragment}
  ${RatificationResultFragment}
`;

export const RUN_RISK_PREDICTION_CONTRIBUTION = gql`
  mutation runRiskPredictionContribution(
    $riskPredictionResultId: Int!
  ) {
    runRiskPredictionContribution(
      riskPredictionResultId: $riskPredictionResultId
    ) {
      riskPredictionResult {
        id
        variablesContribution {
          ... RiskPredictionVariableContributionModelTypeFragment
        }
      }
    }
  }
  ${RiskPredictionVariableContributionModelTypeFragment}
`;

export const REJECT_AUTOMATIC_RATIFICATION_EVALUATION = gql`
  mutation rejectAutomaticRatificationEvaluation(
    $riskEvaluationModelId: Int!
  ) {
    rejectAutomaticRatificationEvaluation(
      riskEvaluationModelId: $riskEvaluationModelId
    ) {
      riskEvaluationInstance {
        id
        ratificationResults {
          ... RatificationResultFragment
        }
      }
    }
  }
  ${RatificationResultFragment}
`;

export const UPLOAD_PREEVALUATIONS = gql`
  mutation uploadPreevaluations($file: Upload!) {
    uploadPreevaluations(file:$file) {
      success
    }
  }
`;

export const EDIT_RISK_BLACKLIST = gql`
  mutation editRiskBlacklist($masterEntityId: String!, $blacklistType: Boolean, $comments: String) {
    editRiskBlacklist(masterEntityId: $masterEntityId, blacklistType: $blacklistType, comments: $comments) {
      masterEntity {
        ...MasterEntityBasicField
        riskBlacklist {
          ...RiskBlacklistFields
          comments
        }
      }
    }
  }
  ${MasterEntityBasicField}
  ${RiskBlacklistFields}
`;
